import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import '../components/layout.css'
import { 
  Box
} from "@chakra-ui/react"
import Seo from '../Seo'
import SliceZone from '../components/SliceZone/SliceZone'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { linkResolver } from "../utils/linkResolver"

import PageHero from "../components/PageHero/PageHero"
import VideoHero from "../components/VideoHero/VideoHero"

// markup
const IndexPage = ({ data }) => {

  const document = data.prismicHomepage.data;

  return (
    <Layout>
      <Seo title="Plateau Forest Products" />
      {/* <VideoHero /> */}
      <PageHero 
        heroImage={document.page_hero_image?.localFile}
        alt={document.page_hero_image.alt}
        pageTitle={document.page_title.text}
        pageSubtitle={document.page_subtitle.text}
      />
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
  {
    prismicHomepage {
          _previewable
          data {
            body {
              ... on PrismicHomepageDataBodyBusinessPromise {
                id
                slice_type
                primary {
                  statement {
                    text
                  }
                  explanation {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                  link_label {
                    text
                  }
                  link {
                    uid
                  }
                }
              }
              ... on PrismicHomepageDataBodyGreenCallToActionBlock {
                id
                slice_type
                primary {
                  block_color
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    url
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicHomepageDataBodyWhatWeTradeBlock {
                id
                slice_type
                items {
                  short_description {
                    text
                  }
                  product_name {
                    text
                  }
                  product_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          aspectRatio: 1
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                          transformOptions: {cropFocus: CENTER}
                        )
                      }
                    }
                  }
                  button_link {
                    url
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicHomepageDataBodyWhiteCallToActionBlock {
                id
                slice_type
                primary {
                  yellow_title {
                    text
                  }
                  white_headline {
                    text
                  }
                  button_link {
                    url
                    uid
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicHomepageDataBodyCompanyOverviewBlock {
                id
                slice_type
                primary {
                  block_title {
                    text
                  }
                  description {
                    text
                  }
                }
                items {
                  brief_success_statement {
                    text
                  }
                }
              }
              ... on PrismicHomepageDataBodyIndustryMembers {
                id
                slice_type
                items {
                  industry_logo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                    alt
                  }
                  image_link {
                    url
                  }
                }
              }
            }
            page_title {
              text
            }
            page_subtitle {
              text
            }
            page_hero_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
`

export default withPrismicPreview(IndexPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver
  },
])